import {SET_LOGIN_TOKEN, SET_TWO_FA_ENABLED} from './actionTypes';

export const setLoginToken = (token) => {
  return {
    type: SET_LOGIN_TOKEN,
    token: token ? "Bearer " + token : token
  };
};

export const setTwoFaEnabled = (enabled) => {
  return {
    type: SET_TWO_FA_ENABLED,
    twoFaEnabled: enabled
  };
};
