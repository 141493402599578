import React, {Component} from 'react';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import QRCode from "react-qr-code";


import {createTwoFa, generateTwoFa} from '../../utils/server';
import {setTopModalContent, showTopModal, hideCenteredModal} from '../../store/actions/modals';
import {setTwoFaEnabled} from '../../store/actions/auth';

class TwoFaEnable extends Component {

  state = {
    qrLoading: true,
    loading: false,
    qrCode: null,
    twoFaSecret: null
  };

  componentDidMount = async () => {
    const { token } = this.props;
    const response = await generateTwoFa(token);
    if (response.error) {
      setTopModalContent("Error", response.error,
        [{ name: "Close", variant: "primary" }]);
      showTopModal();
    } else {
      this.setState({
        qrLoading: false,
        qrCode: response.qr_code,
        twoFaSecret: response.two_fa_secret
      });
    }
    this.setState({
      qrLoading: false,
    });
  };

  onSaveClick = async (e) => {
    e.preventDefault();
    if (this.twoFaCode.value === "") {
      return;
    }
    const { loading, twoFaSecret } = this.state;
    if (loading) return;

    this.setState({ loading: true });
    const { token, setTopModalContent, showTopModal, hideCenteredModal, setTwoFaEnabled } = this.props;
    const serverResponse = await createTwoFa(token, twoFaSecret, this.twoFaCode.value);
    if (serverResponse.error) {
      setTopModalContent("Error", serverResponse.error,
        [{ name: "Close", variant: "primary" }]);
      showTopModal();
    }
    hideCenteredModal();
    setTwoFaEnabled(true);
    this.setState({ loading: false });
  };

  render() {
    const { loading, qrLoading, qrCode, twoFaSecret } = this.state;
    return (
      <Form>
        <Form.Group controlId="qrCode">
          <Form.Label>QR Code</Form.Label>
          <Form.Row>
            {
              qrLoading ? <Spinner animation="border"/> :
                <QRCode value={qrCode} height={200} width={200}/>
            }
          </Form.Row>
          <Form.Text className="text-muted">
            First scan this code with your authenticator app
          </Form.Text>
          <Form.Text className="text-muted">
            Or insert code manually <strong>{twoFaSecret}</strong>
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="2faToken">
          <Form.Label>2FA Code</Form.Label>
          <Form.Control type="text" ref={elem => this.twoFaCode = elem}/>
          <Form.Text className="text-muted">
            Insert the generated code on your authenticator app
          </Form.Text>
        </Form.Group>
        {
          loading ? <Spinner animation="border"/> :
            <Button variant="primary" type="submit" onClick={this.onSaveClick}>Save</Button>
        }
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideCenteredModal: () => dispatch(hideCenteredModal()),
    setTopModalContent: (title, body, buttons) => dispatch(setTopModalContent(title, body, buttons)),
    showTopModal: () => dispatch(showTopModal()),
    setTwoFaEnabled: (enabled) => dispatch(setTwoFaEnabled(enabled)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFaEnable);