import React, {Component} from 'react';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import {login2fa} from '../../utils/server';
import {setTopModalContent, showTopModal, hideCenteredModal} from '../../store/actions/modals';
import {setLoginToken, setTwoFaEnabled} from '../../store/actions/auth';

class TwoFaLogin extends Component {

  state = {
    loading: false
  };

  onLoginClick = async (e) => {
    e.preventDefault();
    if (this.twoFaCode.value === "") {
      return;
    }

    const { loading } = this.state;
    const { twoFaNonce, history } = this.props;
    if (loading) return;

    this.setState({ loading: true });
    const { setTopModalContent, showTopModal, setLoginToken, hideCenteredModal, setTwoFaEnabled } = this.props;

    const result = await login2fa(this.twoFaCode.value, twoFaNonce);
    if (result.token) {
      setLoginToken(result.token);
      setTwoFaEnabled(true);
      hideCenteredModal();
      history.push("/dashboard");
    } else if (result.error) {
      setTopModalContent("Error",
        result.error,
        [
          { name: "Close", variant: "primary" }
        ]);
      showTopModal();
      await this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <Form>
        <Form.Group controlId="2fa">
          <Form.Label>Enter 2FA Code</Form.Label>
          <Form.Control type="text" ref={elem => this.twoFaCode = elem}/>
        </Form.Group>
        {
          loading ? <Spinner animation="border"/> :
            <Button variant="primary" type="submit" onClick={this.onLoginClick}>Continue</Button>
        }
      </Form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideCenteredModal: () => dispatch(hideCenteredModal()),
    setTopModalContent: (title, body, buttons) => dispatch(setTopModalContent(title, body, buttons)),
    showTopModal: () => dispatch(showTopModal()),
    setLoginToken: (token) => dispatch(setLoginToken(token)),
    setTwoFaEnabled: (enabled) => dispatch(setTwoFaEnabled(enabled))
  };
};

export default connect(null, mapDispatchToProps)(TwoFaLogin);