import React, {Component} from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className="d-flex justify-content-center flex-fill align-items-center">
        <h1>Page Not Found (404)</h1>
      </div>
    );
  }
}

export default NotFound;