import {SET_LOGIN_TOKEN, SET_TWO_FA_ENABLED} from '../actions/actionTypes';

const initialState = {
  token: null,
  twoFaEnabled: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case SET_TWO_FA_ENABLED:
      return {
        ...state,
        twoFaEnabled: action.twoFaEnabled
      }
    default:
      return state;
  }
};

export default reducer;